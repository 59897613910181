<template>
  <div class="bus-tabs-box top-padding">
    <top-home :title="tabConfig[code].title"></top-home>
    <van-tabs v-model="tabActive">
      <van-tab :title="tabTitle.tab1">
        <div class="box-iframe">
          <iframe
            class="iframe"
            ref="iframe"
            v-if="code"
            :src="iframeUrl"
          ></iframe>
        </div>
      </van-tab>
      <van-tab :title="tabTitle.tab2">
        <div class="apply_for_list">
          <div
            class="list-item"
            v-for="(item,index) in recordList"
            :key="index"
            @click="opentDetailsFun(item)"
          >
            <div class="item-title-box">
              <div class="title">{{tabConfig[code].title}}</div>
              <div class="state untreated">{{item.shzt}}</div>
            </div>
            <div class="item-text">
              <span>{{tabTitle.timeText}}：{{item.create_time}}</span>
            </div>
          </div>
          <div
            class="empty-box"
            v-if="recordList.length<=0"
          >暂无相关数据</div>
          <div
            class="empty-box"
            v-else
          >没有更多了</div>
        </div>
      </van-tab>
    </van-tabs>
    <van-popup
      v-model="isShowDetails"
      position="right"
      :style="{ width:'80%',height: '100%' }"
    >
      <iframe
        class="iframe-view"
        ref="iframe_view"
        v-if="isShowDetails"
        :src="iframeViewUrl"
      ></iframe>
    </van-popup>
  </div>
</template>

<script>
import config from '@/config'
import { mapGetters } from 'vuex'
import { getFlowDataListApi } from '@/reuqest/api.js'
export default {
  data () {
    return {
      tabActive: 0,
      code: '',
      tabConfig: {
        //故障反馈
        gzfk: {
          type: 'FLOW_GZFK',
          title: '故障反馈 ',
          code: '1',
          formId: '1611606139656163330',
        },
        //问题反馈
        wtfk: {
          type: 'FLOW_WTFK',
          title: '问题反馈',
          code: '2',
          formId: '1610479080531701762',
        },
        //保养申请
        bysq: {
          type: 'FLOW_BYSQ',
          title: '保养申请',
          code: '3',
          formId: '1611637336289529858',
        },
      },
      tabTitle: {},
      iframeWin: null,
      recordList: [],
      isShowDetails: false,
      currDetailsId: '',
    }
  },
  computed: {
    ...mapGetters(['token']),
    iframeUrl () {
      let data = this.tabConfig[this.code]
      console.log(config.FLOW_ID_DATA[data.type].id)
      console.log(config.FLOW_ID_DATA[data.type].key)
      return `${config.PC_FLOW_FORM_VISIT_URL}/${config.FLOW_ID_DATA[data.type].id
        }/${config.FLOW_ID_DATA[data.type].key}/${data.formId}?token=${this.token}`
    },
    iframeViewUrl () {
      return `${config.PC_FORM_VISIT_URL}/wx_${this.code}?token=${this.token}&curr_details_id=${this.currDetailsId}`
    },
  },
  created () {
    this.code = this.$route.params.code
    if (this.code == 'bysq') {
      this.tabTitle = {
        tab1: '立即申请',
        tab2: '我的申请',
        timeText: '申请时间',
      }
    } else {
      this.tabTitle = {
        tab1: '立即反馈',
        tab2: '反馈记录',
        timeText: '反馈时间',
      }
    }
  },
  mounted () {
    window.addEventListener('message', this.handleMessage, true)
    //获取申请记录
    this.getFlowListDataFun()
  },
  destroyed () {
    window.removeEventListener('message', this.handleMessage, true)
  },
  methods: {
    handleMessage (event) {
      const data = event.data
      if (data.cmd == 'setHeight') {
        if (this.tabActive != 1) {
          document.querySelector('.iframe').style.height =
            data.params.data.height + 'px'
        } else {
          document.querySelector('.iframe-view').style.height =
            data.params.data.height + 'px'
        }
      } else if (data.cmd == 'getFlowList') {
        this.getFlowListDataFun()
      }
    },
    getFlowListDataFun () {
      getFlowDataListApi({ type: this.tabConfig[this.code].code }).then(
        (res) => {
          this.recordList = res.data
        }
      )
    },
    opentDetailsFun (item) {
      this.currDetailsId = item.id
      this.isShowDetails = true
    },
  },
}
</script>

<style lang="less" scope>
.bus-tabs-box {
  height: 100%;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  ::-webkit-scrollbar{display:none}
}
.van-tabs {
  height: 100%;
  .van-tabs__content {
    .van-tab__pane {
      height: 100%;
    }
  }
}
.box-iframe {
  overflow-y: auto;
  height: calc(100vh - 44px);
  width: 100%;
  .iframe {
    width: 100%;
    min-height: 100%;
    border: 0;
    overflow: hidden;
    box-sizing: border-box;
  }
}
.iframe-view {
  width: 100%;
  min-height: 100%;
  border: 0;
  overflow: hidden;
  box-sizing: border-box;
}
.apply_for_list {
  height: calc(100vh - 45px);
  overflow-y: auto;
  .list-item {
    padding: 10px 0;
    margin: 0 15px;
    border-bottom: 1px solid #f1f1f1;
    text-align: left;
    .item-title-box {
      display: flex;
      align-items: center;
      .title {
        font-size: 18px;
        font-weight: bold;
        padding-right: 10px;
      }
      .state {
        font-size: 12px;
        color: #fff;
        padding: 2px 5px;
        border-radius: 4px;
        &.untreated {
          background-color: #ffb54c;
        }
        &.processed {
          background-color: #008000;
        }
      }
    }
    .item-text {
      font-size: 16px;
      padding-top: 10px;
      color: #232323;
      span {
        color: #999;
      }
    }
  }
}
.empty-box {
  font-size: 12px;
  color: #999;
  text-align: center;
  width: 100%;
  padding: 20px 0;
}
</style>
<template>
  <div class="contentBQ top-padding">
    <top-home title="保养确认"></top-home>
    <van-tabs
      v-model="active"
      color="rgb(41, 167, 254)"
      title-active-color="rgb(41, 167, 254)"
    >
      <van-tab title="保养确认">
        <div class="bill-list-box">
          <van-list
            v-model="byqrObj.listLoading"
            :finished="byqrObj.listFinished"
            finished-text="没有更多了"
            @load="listOnLoadFun"
          >
            <div
              class="list-item"
              v-for="(item,index) in byqrObj.list"
              :key="index"
              @click="opentDetailsFun(item)"
            >
              <div class="item-content">
                <div class="content-lie">
                  <div class="lie  ellipsis">
                    <span>项目名称:</span>
                    {{item.xmmc}}
                  </div>
                </div>
                <div class="content-lie">
                  <div class="lie ellipsis">
                    <span>设备代码:</span>
                    {{item.sbdm}}
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </div>
      </van-tab>
    </van-tabs>
    <van-popup
      closeable
      v-model="isShowDetails"
      position="right"
      :style="{ width:'100%',height: '100%' }"
    >
      <iframe
        class="iframe-view"
        ref="iframe_view"
        v-if="isShowDetails"
        :src="iframeViewUrl"
      ></iframe>
    </van-popup>
  </div>
</template>

<script>
import config from '@/config'
import { mapGetters ,mapMutations} from 'vuex'
import { getDicTableData, getComputerTableApi,getFlowDataListApi } from '@/reuqest/api.js'

export default {
  computed: {
    ...mapGetters(['token']),
    iframeViewUrl () {
      console.log(`${config.PC_FORM_VISIT_URL}/wx_${this.code}?token=${this.token}&curr_details_id=${this.currDetailsId}`)
      return `${config.PC_FORM_VISIT_URL}/wx_${this.code}?token=${this.token}&curr_details_id=${this.currDetailsId}`
    },
  },
  data () {
    return {
      active: '',
      code: '',
      currDetailsId: '',
      isShowDetails: false,
      tabActive: 0,
      byqrObj: {//保养确认
        tablePage: {
          pageNo: 1,
          pageSize: 10,
          total: 0,
        },
        list: [],
        listLoading: false,
        listFinished: false,
        getDataThrottle: false,
        otherParams: {
          key: 'xmmc',
          value: '',
        },
        tableId: '1561953495903223810',
        formCode: 'byqr'
      },
      searchParams: {
        pageSize: 10,
        order: 'desc',
        column: 'id'
      },
      allDicData: {},//字典数据
      tabConfig: {
        byqr: {
          type: 'FLOW_BYQR',
          title: '保养确认 ',
          code: '1',
          formId: '1562334497574019074',
        }
      },
      recordList: [],
    }
  },
  filters: {},
  created () {
    this.code = this.$route.params.code
    this.active = parseInt(this.$route.query.active)
    
    getDicTableData('hyb_xsht_xsbj_bjzt').then(res => {
      this.allDicData.bjztDic = res.data
    }).catch(() => { })
  },
  mounted () {
    window.addEventListener('message', this.handleMessage, true)
  },
  destroyed () {
    window.removeEventListener('message', this.handleMessage, true)
  },
  methods: {
    //获取账单list
    async listOnLoadFun () {
      let tabType = this.getTabFun()
      this[`${tabType}Obj`].listLoading = true
      if (this[`${tabType}Obj`].getDataThrottle == false) {
        this[`${tabType}Obj`].getDataThrottle = true
        this.getDataTimer = setTimeout(() => {
          return new Promise((resolve) => {
            let params = {
              ...this.searchParams,
              pageNo: this[`${tabType}Obj`].tablePage.pageNo
            }
            if (this[`${tabType}Obj`].otherParams.value) {
              params[this[`${tabType}Obj`].otherParams.key] = this[`${tabType}Obj`].otherParams.value
            }
            getComputerTableApi(this[`${tabType}Obj`].tableId, params).then((res) => {
              let data = res.data
              if (data.records && data.records.length > 0) {
                this[`${tabType}Obj`].list = [...this[`${tabType}Obj`].list, ...data.records]
                this[`${tabType}Obj`].tablePage.total = data.total
                this[`${tabType}Obj`].tablePage.pageNo = this[`${tabType}Obj`].tablePage.pageNo + 1
              } else if (data.total == 0) {
                this[`${tabType}Obj`].list = []
                this[`${tabType}Obj`].tablePage.total = 0
              }
              if (this[`${tabType}Obj`].list.length >= this[`${tabType}Obj`].tablePage.total) {
                this[`${tabType}Obj`].listFinished = true
              } else {
                this[`${tabType}Obj`].listFinished = false
              }
              this[`${tabType}Obj`].listLoading = false
              this[`${tabType}Obj`].getDataThrottle = false
              resolve()
            })
          })
        }, 1000)
      }
    },
    queryDataFun () {
      //重置参数
      let tabType = this.getTabFun()
      this[`${tabType}Obj`].tablePage.pageNo = 1
      this[`${tabType}Obj`].tablePage.total = 0
      let params = {
        ...this.searchParams,
        pageNo: 1,
      }
      if (this[`${tabType}Obj`].otherParams.value) {
        params[this[`${tabType}Obj`].otherParams.key] = this[`${tabType}Obj`].otherParams.value
      }
      getComputerTableApi(this[`${tabType}Obj`].tableId, params).then((res) => {
        let data = res.data
        if (data.records && data.records.length > 0) {
          this[`${tabType}Obj`].list = data.records
          this[`${tabType}Obj`].tablePage.total = data.total
          this[`${tabType}Obj`].tablePage.pageNo = this[`${tabType}Obj`].tablePage.pageNo + 1
        } else if (data.total == 0) {
          this[`${tabType}Obj`].list = []
          this[`${tabType}Obj`].tablePage.total = 0
        }
        if (this[`${tabType}Obj`].list.length < 10) {
          this[`${tabType}Obj`].listFinished = true
        } else {
          this[`${tabType}Obj`].listFinished = false
        }
        this[`${tabType}Obj`].listLoading = false
      })
    },
    getDicDataFun (key, val) {
      let text = ''
      this.allDicData[`${key}Dic`].forEach(item => {
        if (item.value == val) {
          text = item.label
        }
      })
      return text
    },
    getTabFun () {
      this.code = this.$route.params.code
      let tab = ''
      if (this.code == 'byqr') {
        tab = 'byqr'
      }
      return tab
    },
    opentDetailsFun (item) {
      this.code = this.getTabFun()
      this.currDetailsId = item.id
      this.isShowDetails = true
    },
    handleMessage (event) {
      const data = event.data
      if (data.cmd == 'setHeight') {
        document.querySelector('.iframe-view').style.height =
          data.params.data.height + 'px'
      }
    },
  },
}
</script>
 
<style lang="less" scoped>
.contentBQ {
  height: 100vh;
  background-color: rgb(245, 245, 245);
}
.iframe-view {
  width: 100%;
  min-height: 100%;
  border: 0;
  overflow: hidden;
  overflow-y: auto;
  box-sizing: border-box;
}
.tapOne {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  .left-box {
    display: flex;
    align-items: center;
  }
  /deep/.tapOne-div {
    margin-right: 6px;
    .van-cell {
      padding: 3px;
      font-size: 14px;
    }
  }
}
.van-button {
  height: 100%;
  padding: 5px 10px;
}
.bill-list-box {
  background-color: #f5f5f5;
  .list-item {
    background-color: #fff;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 5px 0;
    .item-img {
      margin: 0 10px;
      img {
        width: 45px;
      }
    }
    .item-content {
      width: 100%;
      padding: 8px;
      .je {
        color: #ffb54c;
        line-height: 22px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .content-lie {
        display: flex;
        align-items: center;
        font-size: 14px;
        padding: 3px 0;
        color: #232323;
        text-align: left;
        .lie {
          width: 50%;
        }

        span {
          color: #999;
        }
      }
    }
  }
}
.ellipsis {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>